.wrapper {
  text-align: center;
  margin-left:5px;
  padding-right:10px;
  
}
.right-container {
    width:auto;
    height:100vh;
    overflow-x:hidden;
    max-width:95%;
    padding-right:10px;
    padding-top:100px;
    overflow-y: scroll;

    }
.left-container {
    height:100vh;
    width:220px;
    float:left;
    border-right:solid darkgrey 1px;
    text-align:left; 
    overflow-x:hidden;   
    margin-left:5px;
    padding-top:15px;
    padding-right:5px;
    
    }

.centered-p-in-div {
  text-align:center; 
  vertical-align: middle;
  display: table-cell; 
  padding-left:4px;
}

.logo-container{width:100%;text-align: center;padding-top: 40px;}
.sideMenu-container{width:auto;max-width:95%;min-width:95%;padding-left:5%;clear: both;padding-top: 20px;}
.std-logo-container{width:90px;margin-left:50px;margin-right:20px}

.publicationBox {
  max-width: 250px;
  max-height: 210px;
  min-width:  250px;
  min-height: 210px;
  border: solid darkgrey 0.75px;
  padding: 15px 5px 15px 5px;
  margin: 10px;
}


.hover {
  background-color: #E4DED4;
  border: solid darkgrey 1px;
}

.background-video{

  height: auto;
  width: 100%;
  padding-left:10px;
  padding-right:10px;
  padding-top:30px;
  position: relative; /* optional depending on what you want to do in your app */
  
  
  }